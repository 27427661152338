section.places-list {
	.places-filter {
		margin-bottom: 1rem;

		.select-wrapper {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: calc(100% + 0.5rem);
				left: 0;
				right: $input-btn-padding-x + 1.5rem;
				height: 0.125rem;
				background: $green;
			}
		}

		select {
			border: none;
			color: $green-dark;
			font-weight: 700;
			padding-left: 0;
		}
	}

	.county {
		margin: 1rem 0;
	}

	ul.places {
		li {
			padding: 1rem 0;

			@include media-breakpoint-up(md) {
				padding: 1.5rem 0;
			}

			&:not(:last-child) {
				border-bottom: 0.5px solid $green;
			}
		}
	}

	.place-title {
		margin-bottom: 0;
	}

	.details {
		@include media-breakpoint-only(sm) {
			flex-wrap: wrap;
		}

		a {
			color: inherit;
		}

		.address {
			margin-top: 1rem;
			flex-grow: 3;

			@include media-breakpoint-only(sm) {
				width: 100%;
			}
		}

		.contact {
			line-height: 2;
			display: flex;
			flex-direction: column;
			flex-grow: 2;
			margin-top: 1rem;

			@include media-breakpoint-only(sm) {
				width: 50%;
			}

			@include media-breakpoint-up(md) {
				margin-top: 0;
			}

			.phone {
				text-decoration: none;
			}
		}

		.links {
			line-height: 2;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			margin-top: 1rem;

			@include media-breakpoint-only(sm) {
				width: 50%;
			}

			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}

		.chevron {
			padding-left: 1rem;
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}

			span {
				display: block;
				margin-top: 1.75rem;
				width: .625rem;
				height: 1.125rem;
				@extend .chevron-right-green;
			}
		}
	}
}
