footer {
	background: $white;
	position: relative;
	--padding: 4rem;
	padding: var(--padding) 0;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		--padding: 5rem;
	}

	@include media-breakpoint-up(xxl) {
		--padding: 6.5rem;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		z-index: 0;
		background: escape-svg(url('data:image/svg+xml,<svg height="1435.233" viewBox="0 0 1552.905 1435.233" width="1552.905" xmlns="http://www.w3.org/2000/svg"><path d="m1668.25 481.694c-277.071-242.362-731.48-425.647-1074.028-255.586-147.249 73.21-253.722 200.492-299.653 358.426-52.4 179.988-20.689 384.458 86.963 560.543 196.777 322.353 622.5 564.485 966.829 393.553 202.421-100.682 433.414-370.325 469.639-647.166a545.833 545.833 0 0 0 4.736-70.784c0-133.911-52.636-250.091-154.486-338.986z" fill="#0f350d" transform="translate(-269.831 -159.842)"/></svg>')) 50% 0% / cover no-repeat;
		opacity: 0.04;
		height: 100%;
		width: 70rem;
		left: -20rem;

		@include media-breakpoint-up(sm) {

			left: -10rem;
		}

		@include media-breakpoint-up(xl) {
			width: 80rem;
			left: -20rem;
		}

		@include media-breakpoint-up(xxl) {
			width: 1553px;
			left: -24rem;
		}
		@media (min-width: 1600px) {
			left: -17rem;
		}
	}

	.container {
		position: relative;
		z-index: 1;

        @media (min-width: 1600px) {
            max-width: 1560px;
        }
        @media (min-width: 1720px) {
            max-width: 1680px;
        }
	}

	.footer-grid {
		display: grid;
		grid-template-areas: "about" "office" "contact" "follow" "updates";
		gap: 1rem;

		@include media-breakpoint-up(sm) {
			grid-template-areas: "about about" "office contact" "follow updates";
			gap: 1.5rem;
		}

		@include media-breakpoint-up(md) {
			grid-template-areas: "about office contact" "updates updates follow";
			grid-template-columns: 2fr 1fr 1fr;
		}

		@include media-breakpoint-up(xl) {
			grid-template-areas: "about office contact follow" "about office contact updates";
			grid-template-columns: 20fr 13fr 13fr 13fr;
			justify-items: stretch;
			justify-content: space-between;
			gap: 2rem;
		}

		@include media-breakpoint-up(xxl) {
			grid-template-columns: 28fr 13fr 13fr 18fr;
		}

		@media (min-width: 1600px) {
			grid-template-areas: "about office contact follow updates";
			grid-template-columns: 28fr 13fr 13fr 9fr 18fr;
			gap: 3rem;
		}

		.about {
			grid-area: about;
		}
		.office {
			grid-area: office;
		}
		.contact {
			grid-area: contact;
		}
		.follow {
			grid-area: follow;
		}
		.updates {
			grid-area: updates;
		}
	}

	h6 {
		@include media-breakpoint-up(lg) {
			font-size: 1.125rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.25rem;
		}
	}

	h3, h4, h5, h6 {
		line-height: 2rem;
		margin-bottom: 1rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 1.25rem;
		}
	}

	p {
		@include media-breakpoint-up(xl) {
			font-size: 1.0625rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: inherit;

			&[href^="mailto"], &[href^="tel"] {
				text-decoration: none;
			}
		}
	}

	.logos {
		margin-top: 2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-up(xl) {
			margin-top: 2.5rem;
		}
	}

	.social {
		display: flex;
		justify-content: space-between;
		max-width: 9rem;

		a {
			display: block;
			width: 2.5rem;
			height: 2.5rem;

			&.linkedin {
				background: escape-svg(url('data:image/svg+xml,<svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" fill="#6f872b" r="20"/><path d="m3.786 16.913h-3.507v-11.291h3.507zm-1.755-12.832a2.04 2.04 0 1 1 2.031-2.05 2.048 2.048 0 0 1 -2.031 2.05zm14.878 12.832h-3.5v-5.5c0-1.31-.026-2.99-1.823-2.99-1.823 0-2.1 1.423-2.1 2.9v5.591h-3.5v-11.292h3.359v1.54h.049a3.684 3.684 0 0 1 3.318-1.823c3.549 0 4.2 2.337 4.2 5.372v6.2z" fill="#fff" transform="translate(11.41 9.664)"/></svg>')) center / contain no-repeat;
			}
			&.facebook {
				background: escape-svg(url('data:image/svg+xml,<svg height="40" viewBox="0 0 39 40" width="39" xmlns="http://www.w3.org/2000/svg"><ellipse cx="19.5" cy="20" fill="#6f872b" rx="19.5" ry="20"/><path d="m13.257 10.38v-3.691h2.977v-1.857a4.858 4.858 0 0 1 1.3-3.421 4.149 4.149 0 0 1 3.166-1.411h2.957v3.692h-2.957a.651.651 0 0 0 -.521.319 1.3 1.3 0 0 0 -.232.783v1.894h3.711v3.691h-3.713v8.95h-3.711v-8.949z" fill="#fff" transform="translate(.248 10.201)"/></svg>')) center / contain no-repeat;
			}
			&.instagram {
				background: escape-svg(url('data:image/svg+xml,<svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" fill="#6f872b" r="20"/><path d="m9.665 0c-2.626 0-2.954.012-3.985.058a7.126 7.126 0 0 0 -2.346.449 4.733 4.733 0 0 0 -1.712 1.115 4.716 4.716 0 0 0 -1.115 1.712 7.106 7.106 0 0 0 -.449 2.346c-.048 1.031-.058 1.359-.058 3.985s.012 2.953.058 3.984a7.131 7.131 0 0 0 .449 2.346 4.74 4.74 0 0 0 1.115 1.712 4.726 4.726 0 0 0 1.712 1.115 7.135 7.135 0 0 0 2.346.449c1.031.048 1.359.058 3.984.058s2.953-.012 3.984-.058a7.152 7.152 0 0 0 2.346-.449 4.939 4.939 0 0 0 2.827-2.827 7.131 7.131 0 0 0 .449-2.346c.048-1.031.058-1.359.058-3.984s-.012-2.953-.058-3.984a7.147 7.147 0 0 0 -.449-2.346 4.743 4.743 0 0 0 -1.115-1.712 4.709 4.709 0 0 0 -1.711-1.116 7.11 7.11 0 0 0 -2.346-.449c-1.031-.048-1.359-.058-3.984-.058zm0 1.74c2.58 0 2.887.013 3.906.057a5.325 5.325 0 0 1 1.794.334 3.18 3.18 0 0 1 1.835 1.834 5.336 5.336 0 0 1 .333 1.794c.046 1.02.056 1.326.056 3.906s-.012 2.887-.06 3.906a5.439 5.439 0 0 1 -.339 1.794 3.069 3.069 0 0 1 -.724 1.113 3.015 3.015 0 0 1 -1.111.722 5.375 5.375 0 0 1 -1.8.333c-1.026.046-1.328.056-3.913.056s-2.888-.012-3.913-.06a5.48 5.48 0 0 1 -1.8-.339 2.993 2.993 0 0 1 -1.111-.724 2.935 2.935 0 0 1 -.725-1.111 5.485 5.485 0 0 1 -.338-1.8c-.036-1.015-.049-1.328-.049-3.9s.013-2.888.049-3.915a5.479 5.479 0 0 1 .338-1.8 2.865 2.865 0 0 1 .725-1.112 2.859 2.859 0 0 1 1.108-.728 5.349 5.349 0 0 1 1.789-.339c1.027-.036 1.329-.048 3.913-.048l.036.024zm0 2.962a4.963 4.963 0 1 0 4.963 4.963 4.962 4.962 0 0 0 -4.963-4.965zm0 8.184a3.222 3.222 0 1 1 3.222-3.222 3.221 3.221 0 0 1 -3.222 3.222zm6.319-8.38a1.16 1.16 0 1 1 -1.16-1.159 1.161 1.161 0 0 1 1.16 1.159z" fill="#fff" transform="translate(10.291 10.201)"/></svg>')) center / contain no-repeat;
			}
		}
	}

	.btn {
		@include button-variant($green-lighter, $green-lighter, $green-dark, null, null, $green);
	}
}
