section.contact-people {
	.contact-cards {
		display: grid;
		justify-content: space-between;
		--col-count: 2;
		--col-width: calc(50vw - #{$grid-gutter-width});
		grid-template-columns: repeat(var(--col-count), var(--col-width));
		row-gap: 4rem;

		@include media-breakpoint-up(sm) {
			--col-width: calc(50% - #{$grid-gutter-width});
		}
		@include media-breakpoint-up(md) {
			--col-width: 17rem;
			row-gap: 5rem;
		}
		@include media-breakpoint-up(lg) {
            --col-width: 16rem;
			--col-count: 3;
		}
		@include media-breakpoint-up(xl) {
			--col-width: 18rem;
			row-gap: 6rem;
		}
		@include media-breakpoint-up(xxl) {
			--col-width: 21rem;
			row-gap: 8rem;
		}

		&.single-card {
			float: left;
		}
	}

	.contact-card {
		text-align: center;
		color: $gray-900;
		font-weight: normal;
		letter-spacing: 1px;

		&.pull-left {
			text-align: left;
		}

		.contact-card-image {
			margin-bottom: 1rem;
		}

		.contact-card-name {
			color: $green-dark;
			font-weight: 700;
			line-height: 1.3;
			padding-bottom: 0.25rem;
		}

		.contact-card-text,
		.contact-card-email,
		.contact-card-phone {
			@include media-breakpoint-down(sm) {
				font-size: 0.875rem;
			}
		}

		.contact-card-email {
			display: block;
			margin-top: 1em;
			text-decoration: underline;
			color: inherit;
		}

		.contact-card-phone {
			display: block;
			text-decoration: underline;
			color: inherit;

			&:empty {
				display: none;
			}
		}
	}
}
