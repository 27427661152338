.breadcrumb {
	max-width: calc(100vw - 6rem - #{$grid-gutter-width});
	margin-bottom: 0;

	&.over {
		position: absolute;
		z-index: $zindex-sticky - 1;
		margin: 1.5rem 0 0;
	}

	a {
		text-decoration: none;
	}

	li.active {
		text-decoration: underline;
	}

	&.white {
		.breadcrumb-item {
			color: $white;

			&.active {
				color: $white;
			}
			+ .breadcrumb-item::before {
				color: $white;
			}
		}

		a {
			color: $white;
		}
	}

	&.green {
		.breadcrumb-item {
			color: $green-dark;
		}
	}
}
