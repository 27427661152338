section.three-col-articles {
	a {
		text-decoration: none;
	}
	img {
		margin-bottom: 1rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 1.25rem;
		}
	}
	h3 {
		position: relative;
		margin-bottom: 2rem;

		&::after {
			content: '';
			position: absolute;
			top: calc(100% + 1.5rem);
			left: 50%;
			height: 0.125rem;
			background: $green;
			width: 100%;
			max-width: min(16rem, calc(100% - 4rem));
			transform: translateX(-50%);
		}
	}

	.meta {
		p {
			margin: 0.5rem 0 0.25rem;
			font-weight: 700;

			@include media-breakpoint-up(xl) {
				margin-top: 0.75rem;
			}
		}
		.date {
			display: block;
			font-size: 0.875rem;
		}
	}

	.row-buttons {
		padding-top: var(--section-padding);

        @include media-breakpoint-up(md) {
            padding-top: calc(var(--section-padding) * 0.75);
        }
	}

	.col:not(:nth-child(3n)) {
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}

	.col {
		display: flex;
		flex-direction: column;

		a {
			height: 100%;
			display: flex;
			flex-direction: column;

			h3 {
				flex-grow: 1;
			}
		}
	}
}
