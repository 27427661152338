section.flipbook {
    .ratio-flipbook {
        --bs-aspect-ratio: 141.42%;

        @include media-breakpoint-up(md) {
            --bs-aspect-ratio: 75%;
        }

        @include media-breakpoint-up(xl) {
            --bs-aspect-ratio: 55%;
        }

        @media (min-width: 1600px) {
            --bs-aspect-ratio: 50%;
        }
    }
}
