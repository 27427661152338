body {
	@include media-breakpoint-up(xl) {
		font-size: 1.125rem;
	}
}

.nav-title {
	font-weight: 700;
	color: $green-dark;
	line-height: 1.625rem;
	display: flex;
	margin-bottom: 0.625rem;

	&.nav-title-gap {
		margin-bottom: 3rem;

		@include media-breakpoint-up(xxl) {
			margin-bottom: 3.75rem;
		}

		.meta > & {
			margin-bottom: 2rem;

			@include media-breakpoint-up(xxl) {
				margin-bottom: 2.75rem;
			}
		}
	}

	span {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: calc(100% + 0.5rem);
			left: 0;
			right: 0;
			height: 0.125rem;
			background: $green;
		}
	}

	.container > & {
		margin-bottom: 1.625rem;

		@include media-breakpoint-up(md) {
			margin-bottom: 2.25rem;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 2.5rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-bottom: 3.5rem;
		}
	}
}

p {
	letter-spacing: 0.05em;
}

.table {
    th {
        color: $green-dark;
    }
    > :not(:first-child) {
        border-top-color: $table-border-color;
    }
    > :not(caption)>*>* {
        @include media-breakpoint-up(xl) {
            padding: 0.75rem 1rem;
        }
    }
}
