section.accordion {
	.accordion {
		.accordion-item {
			border-bottom: 0.5px solid $green;

			.accordion-header {
				font-size: inherit;

				.accordion-button {
					font-weight: 700;
					font-size: inherit;

					&::after {
						width: 1.25rem;
						height: 1.25rem;
					}
				}
			}

			.read-more {
				margin-top: 1.5rem;
			}
		}
	}
}
