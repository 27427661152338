header {
	.mobile-menu {
		$left-padding: 3.75rem;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100vw;
		background: $white;

		.navbar-nav-content-wrapper {
			height: calc(100vh - 5rem);
			overflow: auto;
			display: grid;
			grid-template: 1 / 1;
			justify-items: center;
			align-items: center;
			padding: 1rem 0 3rem;

			.quick-links {
				top: 0;
			}
		}

		.navbar-nav {
			justify-content: center;
			width: 100%;

			> .nav-item {
				> .nav-link {
					padding-left: $left-padding;
					font-size: 1.25rem;
					font-weight: 600;
					letter-spacing: 0.0625em;

					&.dropdown-toggle::after {
						display: none;
					}
				}
				form {
					padding-left: $left-padding;
					padding-top: 2rem;

					input[type="text"] {
						border-color: $green-dark;
						border-radius: 4rem !important;
						padding-left: 1.25rem;
						letter-spacing: 0;
						font-size: 0.875rem;

						&::placeholder {
							font-weight: 400;
						}
					}

					button {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						width: 3rem;
						border-radius: 0;
						background: url(../../../images/search-icon.svg) 50% 50% / auto 40% no-repeat;
						filter: grayscale(1);
						opacity: 0.5;
						z-index: 5;
					}
				}

				> form {
					input[type="text"] {
						border-radius: 4rem 0 0 4rem !important;
						border-right: 0;
					}
				}

				.submenu-wrapper {
					background: $green-light-bg;

					.submenu-content {
						padding: 2rem $left-padding 3rem;
					}

					form {
						padding-left: 0;

						button {
							filter: none;
							opacity: 0.8;
						}

						input[type="text"] {
							border-color: $white;
						}
					}

					.submenu-header {
						text-decoration: none;
						color: $green-dark;
						font-weight: 700;
						display: block;
						padding-bottom: 0.125rem;

						&:not(:first-child) {
							margin-top: 1.375rem;
						}
					}

					.submenu {
						padding: 0;
						margin: 0;

						li {
							list-style: none;
							font-size: 0.875rem;
							margin-bottom: 0.25rem;

							&:last-child {
								margin-bottom: 0;
							}

							a {
								color: inherit;
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}
}
