body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.mobile-menu-open,
	&.megamenu-open {
		overflow: hidden;
	}

	&::after {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		z-index: $zindex-sticky - 1;
		mix-blend-mode: multiply;
		background: $body-color;
		opacity: 0;
		transition: $transition-fade;
	}

	&.megamenu-open::after {
		opacity: 0.12;
		width: 100%;
		height: 100%;
	}
}

#jump-to-content {
	position: absolute;
	overflow: hidden;
	z-index: 99999;
	text-align: center;
	width: 100%;
	color: $green-dark;
	top: -80px;
	font-size: 1.25rem;
	transition: all .3s ease;
	display: flex;
	justify-content: center;
	align-items: center;

	@include media-breakpoint-up(lg) {
		font-size: 1.5rem;
	}

	&:focus {
		height: 100%;
		text-align: center;
		width: 100%;
		top: 0;
		background: rgba($white, 0.85);
	}
}

.container, .container-fluid {
	@include media-breakpoint-up(sm) {
		--bs-gutter-x: 1rem;
	}
}

.container-3xl {
	@media (min-width: 1440px) {
		max-width: 1400px;
	}
}

.row > .pull-left {
	@media (min-width: 1800px) {
		margin-left: -100px;
	}
}

.row > .pull-right {
	@media (min-width: 1800px) {
		margin-right: -100px;
	}
}

.col-spacer {
	max-width: 6rem;
}

section {
	--section-padding: 4rem;
    scroll-margin-top: var(--header-height);

	@include media-breakpoint-up(lg) {
		--section-padding: 5rem;
	}
	@include media-breakpoint-up(xl) {
		--section-padding: 6rem;
	}
	@include media-breakpoint-up(xxl) {
		--section-padding: 6.75rem;
	}
}

section.bg-white {
	background: $white;
	padding: var(--section-padding) 0;

	+ section.bg-white {
		padding-top: 0;
	}
}

section.bg-image:last-of-type {
	margin-bottom: var(--section-padding);
}

section.has-navigation {
	.col-push-down {
		@include media-breakpoint-up(md) {
			padding-top: 2.25rem;
		}
	}
}

main {
	position: relative;
}
