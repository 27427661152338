section.one-col-image-text {
	.col-image {
		padding-bottom: 1.5rem;

		.img-wrapper {
			overflow: hidden;

			&.hover-zoom {
				img {
					transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1); // https://easings.net/#easeInOutQuart
				}

				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}

	.col-text {
		@include media-breakpoint-up(lg) {
			margin-right: calc(100% / #{$grid-columns});
		}
	}

	.h1 {
		margin-bottom: 0.5em;
	}

	p {
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-bottom: 3.5rem;
		}
	}

	.mobile-image {
		@include media-breakpoint-up(sm) {
			max-width: 75%;
		}
	}
}
