section.news-carousel {
    .col-image {
        padding-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            padding-top: 2.25rem;
        }
    }

    .news-details-wrapper {
        padding-bottom: 0.5rem;

        @media (min-width: 1600px) {
            padding-right: 7.5rem;
        }

        .meta {
            .date {
                font-size: 0.875rem;
                color: rgba($green, 0.3);
                padding-top: 0.625rem;
            }
        }

        h1, h2 {
            margin-top: 1rem;
            margin-bottom: 0.5em;

            a {
                text-decoration: none;
            }
        }

        p {
            margin-bottom: 2rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include media-breakpoint-up(lg) {
                margin-bottom: 2.5rem;
            }

            @include media-breakpoint-up(xxl) {
                margin-bottom: 3rem;
            }
        }
    }

    .carousel-controls {
        padding-top: 2rem;

        @include media-breakpoint-up(lg) {
            padding-top: 3rem;
        }

        display: flex;
        justify-content: center;

        button {
            border: none;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.25rem 0.5rem;

            &.control-prev {
                @media (min-width: 360px) {
                    margin-right: 5vw;
                }

                @include media-breakpoint-up(md) {
                    margin-right: 5rem;
                }
            }
            &.control-next {
                @media (min-width: 360px) {
                    margin-left: 5vw;
                }

                @include media-breakpoint-up(md) {
                    margin-left: 5rem;
                }
            }

            &.indicator {
                margin: 0 2vw;


                @include media-breakpoint-up(md) {
                    margin: 0 1.5rem;
                }

                span {
                    display: block;
                    background: rgba($green-light-bg, 0.9);
                    width: 1rem;
                    height: 0.375rem;
                    border-radius: 1rem;
                    transition: $transition-base;

                    @include media-breakpoint-up(md) {
                        width: 1.25rem;
                    }
                }

                &.active {
                    span {
                        background: $green-dark;
                    }
                }
            }

            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                width: 1.75rem;
                height: 1.75rem;
            }
        }
    }
}
