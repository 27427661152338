body.story-open {
	overflow: hidden;
}

#story-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $zindex-tooltip + 20;
	overflow-x: hidden;
	overflow-y: scroll;
	background: $white;
	outline: 0;
	transition: $transition-fade;

	.loader {
		display: none;
	}

	.content {
		display: block;
		position: relative;
	}

	&.loading {
		display: flex;
		justify-content: center;
		align-items: center;

		.content {
			display: none;
		}

		.loader {
			width: min(40vw, 14rem);
			aspect-ratio: 1;
			display: grid;
			background: $green-dark;
			clip-path: inset(10%);

			&::before {
				content: '';
				padding: 10%;
				--_g: no-repeat content-box radial-gradient(50% 50%, #000 95%, #0000);
				background: var(--_g), var(--_g), var(--_g), var(--_g), $white;
				background-size: 20% 20%;
				filter: blur(5px) contrast(20);
				mix-blend-mode: lighten;
				animation: loader-l 3s infinite, loader-s 3s infinite;
			}
		}
	}

	&:not(.loading) {
		overflow-x: hidden;
		overflow-y: auto;
		outline: 0;
	}
}

@keyframes loader-l {
	0%,
	100%{background-position:top   ,right ,bottom,left  }
	20% {background-position:center,right ,bottom,left  }
	40% {background-position:center,center,bottom,left  }
	60% {background-position:center,center,center,left  }
	80% {background-position:center,center,center,center}
}
@keyframes loader-s {
	0%,
	25%,
	90%{background-size: 20% 20%,20% 20%,20% 20%,20% 20%}
	40%,
	45%{background-size: 30% 30%,20% 20%,20% 20%,20% 20%}
	60%,
	65%{background-size: 40% 40%,20% 20%,20% 20%,20% 20%}
	80%{background-size: 50% 50%,20% 20%,20% 20%,20% 20%}
}
