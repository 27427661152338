section.search-results {
	h2 {
		margin-bottom: 1em;

		&:not(:first-child) {
			margin-top: 1em;
		}
	}
	a {
		color: inherit;
		text-decoration: none;
	}
}
