$header-breakpoint: lg;

body {
	--header-height: 5rem;

	@include media-breakpoint-up($header-breakpoint) {
		--header-height: 6rem;
	}
    @include media-breakpoint-up(xl) {
        --header-height: 6.75rem;
    }
}

header {
	height: var(--header-height);
	background: $white;
	position: sticky;
	z-index: $zindex-sticky;
	top: 0;

	.container-fluid {
		--bs-gutter-x: 1.25rem;

		@include media-breakpoint-up($header-breakpoint) {
			> .row {
				--bs-gutter-x: 2.5rem;
			}
		}
	}

	.col-logo {
		@include media-breakpoint-up(xxl) {
			padding-left: 4rem;
		}
	}
	.img-logo {
		height: 2rem;

		@include media-breakpoint-up(lg) {
			height: 2.25rem;
		}

		@include media-breakpoint-up(xxl) {
			height: 3rem;
		}
	}

	.menu {
		margin: 0;
		padding: 0;
		display: flex;

		li {
			list-style: none;
			letter-spacing: 1px;
			font-weight: 600;
			font-size: 1rem;
			margin: 0 1.25vw;

			@include media-breakpoint-up(xl) {
				font-size: 1.0625rem;
				margin: 0 1.5vw;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 1.125rem;
				margin: 0 2vw;
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				display: block;
				padding: 0.5rem 0;
				text-decoration: none;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 0;
					height: 0.125rem;
					background: $green;
					transition: $transition-base;
				}

				&:hover::after {
					left: 0;
					width: 100%;
				}
			}

			&.active a::after {
				left: 0;
				width: 100%;
			}
		}
	}

	.mega-menu-collapse {
		position: absolute;
		top: var(--header-height);
		left: 0;
		width: 100%;
		z-index: $zindex-sticky;

		&.opening {
			z-index: $zindex-sticky + 1;
		}
	}

	.mega-menu {
		background: linear-gradient($green-lighter-bg, $green-lighter-bg), linear-gradient($white, $white);
		min-height: 22.5rem;

		@include media-breakpoint-down($header-breakpoint) {
			display: none !important;
		}

		.col-menu, .col-shortcuts {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}

		.col-shortcuts {
			background: $green-light-bg;
			mix-blend-mode: multiply;

            .submenu-header {
                color: black;
            }
		}

		.submenu-header {
			color: $green-dark;
			font-weight: 700;
			font-size: 1.125rem;
			text-decoration: none;
			margin-bottom: 0.125rem;

            @include media-breakpoint-up(xl) {
                font-size: 1.1875rem;
            }

            &.empty {
                display: inline-block;

                &::before {
                    content: ' ';
                }

                &::after {
                    display: none;
                }
            }
		}

        a.submenu-header {
            &::after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-left: 0.5rem;
                width: 0.875rem;
                height: 0.875rem;
                background: url('data:image/svg+xml,<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 6L1 6M14 6L9.125 11M14 6L9.125 1" stroke="%236F872B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') 50% 50% / contain no-repeat;
            }
        }

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				color: $body-color;
				font-size: 0.9375rem;
				line-height: 1.25rem;
				margin: 0.25rem 0;
				padding: 0.125rem 0;

				a {
					text-decoration: none;
					color: inherit;

					&:hover {
						font-weight: 700;
						text-decoration: underline;
					}
				}

				&.active {
					a {
						font-weight: 700;
						text-decoration: underline;
					}
				}
			}

			+ .submenu-header {
				display: inline-block;
				margin-top: 2rem;
			}

            &:not(.submenu) + ul:not(.submenu) {
                margin-top: 2rem;
            }
		}

		.col-search {
			padding-top: 4rem;

			.input-group {
				max-width: 20rem;
				margin-left: -1.75rem;
			}

			input[type="text"] {
				border-color: $white;
				border-radius: 4rem !important;
				padding-left: 1.75rem;
				letter-spacing: 0;
				font-size: 1.25rem;
				height: 3.375rem;
				font-weight: 300;

				&::placeholder {
					color: $green-dark;
					font-weight: 300;
				}
			}

			button {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				width: 4rem;
				background: url(../../../images/search-icon.svg) 50% 50% / auto 40% no-repeat;
				z-index: 5;
				border-radius: 0 4rem 4rem 0;
			}
		}

		.mega-menu-close {
			position: absolute;
			top: 1rem;
			right: 1.5rem;
			width: 2rem;
			height: 2rem;
			background: transparent escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$green}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")) center / 0.625rem auto no-repeat;
		}
	}

	.col-desktop-search {
		padding-right: 0;

        .admin-edit-button-wrapper {
            position: absolute;
            top: 0;
            right: 0.25rem;
            height: 1.625rem;
            align-items: center;

            .btn {
                font-size: 0.75rem;
                letter-spacing: 0.05em;
            }

            @include media-breakpoint-up(xl) {
                height: 1.875rem;
            }
        }

		.input-group {
			input[type="text"] {
				border-color: $green-dark;
				border-radius: 4rem 0 0 4rem !important;
				border-right: 0;
				padding-left: 1.5rem;
				padding-right: 2.5rem;
				letter-spacing: 0;
                font-size: 1rem;
				font-weight: 400;
				height: 2.75rem;
				width: 10rem;
				transition: $transition-base;

				&::placeholder {
					transition: $transition-base;
				}

				@include media-breakpoint-up(xl) {
					padding-right: 3.5rem;
                    height: 3rem;
                    font-size: 1.0625rem;
					width: 13.5rem;
				}

				body.megamenu-open & {
                    color: transparent;
					background: $green-dark;
					padding-right: 0;
					width: 3.875rem;

					&::placeholder {
						color: transparent;
					}
				}
			}

			button {
				position: absolute;
				right: 0.5rem;
				top: 0;
				bottom: 0;
				width: 3rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
				border-radius: 0;
				background: url(../../../images/search-icon.svg) 50% 50% / auto 1.125rem no-repeat;
				z-index: 5;
				transition: $transition-base;

				@include media-breakpoint-up(xl) {
					width: 2rem;
				}

				body.megamenu-open & {
					filter: brightness(0) saturate(100%) invert(100%);
					right: 0;
					padding-left: 1.875rem;
					padding-right: 2rem;
				}
			}
		}
	}

	.col-story-close {
		@media (min-width: 1400px) {
			padding-right: 4rem;
		}
	}
	.story-close {

	}
}

.breadcrumbs {
	font-size: 0.875rem;

	a {
		text-decoration: none;

		&.active {
			text-decoration: underline;
		}
	}
}

.quick-links {
	--quick-links-top: 2rem;
	position: absolute;
	right: 0;
	top: var(--quick-links-top);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 9;

	@include media-breakpoint-up($header-breakpoint) {
		--quick-links-top: 2.75rem;
	}

	.quick-link {
		height: 2.25rem;
		border-radius: 2rem 0 0 2rem;
		font-size: 0.875rem;
		letter-spacing: 0.05em;
		padding: 0 1.25rem 0 1.5rem;
		display: flex;
		align-items: center;
		background: $gray-light;
		margin-bottom: 1rem;

		@include media-breakpoint-up(xxl) {
			height: 3rem;
			font-size: 1.125rem;
			padding: 0 2rem 0 2.25rem;
		}

		a {
			text-decoration: none;
			font-weight: 700;
		}

		ul {
			margin: 0;
			padding: 0;
			display: flex;

			li {
				list-style: none;

				&:not(:first-child) {
                    display: flex;

                    &::before {
                        content: '/';
                        width: 1rem;
                        text-align: center;
                        display: inline-block;
                    }
				}

				a {
					font-weight: 400;
				}

				span.active {
					font-weight: 700;
					text-decoration: underline;
				}
			}
		}
	}

    &.vertical {
        --quick-links-top: 0;
        flex-direction: row-reverse;
        gap: 1rem;
        position: relative;

        @include media-breakpoint-up($header-breakpoint) {
            position: absolute;
        }

        .quick-link {
            margin-bottom: 0;

            &:not(:first-child) {
                border-radius: 2rem;
            }
        }
    }
}
