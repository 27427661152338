section.sustainability-blocks {

	h2 {
		margin-bottom: 0.5em;

		a {
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}
	}

	.content-wrapper {
		@include media-breakpoint-up(xxl) {
			max-width: min(80%, 40rem);
		}

		p {
			letter-spacing: 0;
		}
	}

	.anim-icons {
		display: inline-flex;
		margin-bottom: 1rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 1.25rem;
		}

		img {
			height: clamp(5rem, 12vw, 8.5rem);
			float: left;
			transition: all .35s ease;
			transform-origin: bottom right;
		}

		&.anim:hover img {
			margin-right: 0.75rem;
			transform: rotate(10deg);
		}
	}

	.row.justify-content-end,
	.row.flex-row-reverse {
		.anim-icons {
			img {
				transform-origin: bottom left;
			}
			&.anim:hover img {
				margin-right: 0;
				margin-left: 0.75rem;
				transform: rotate(-10deg);
			}
		}

		.content-wrapper {
			margin-left: auto;
		}
	}

    .col-image {
        padding-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 3rem;
        }

        @include media-breakpoint-up(xxl) {
            padding-bottom: 4rem;
        }
    }

	.links {
		font-weight: 700;

		@include media-breakpoint-up(sm) {
			margin-bottom: 0;
		}

		li {
			padding: 0.375rem 0;

			@include media-breakpoint-up(sm) {
				padding: 0.625rem 0;
			}
		}

		a {
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}
	}
}
