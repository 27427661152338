.btn {
	letter-spacing: 0;

	&.btn-success {
		color: $white;
	}

	&.btn-alt {
		@include button-variant(
				$green-btn-bg, $green-btn-bg, $green-btn,
				tint-color($green-btn-bg, 10%), tint-color($green-btn-bg, 10%), shade-color($green-btn, 10%),
				tint-color($green-btn-bg, 10%), tint-color($green-btn-bg, 10%), shade-color($green-btn, 10%)
		);
	}

	&:not(.btn-lg) {
		@include media-breakpoint-down(sm) {
			padding: 0.375rem 2rem;
			font-size: 0.875rem;
		}
	}
}
