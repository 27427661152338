section.place-details {
	a {
		color: inherit;
	}

	.contact {
		line-height: 2;
		display: flex;
		flex-direction: column;
		margin-top: 1rem;

		.phone {
			text-decoration: none;
		}
	}

	.address {
		margin: 1rem 0 0.5rem;

		@include media-breakpoint-up(md) {
			margin-top: 2rem;
		}
	}
}
