section.article {
	h1, h2 {
		margin-bottom: 0.5em;
	}

	p {
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 2.5rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-bottom: 3rem;
		}
	}

	.btn + .btn {
		margin-left: 1rem;

		@include media-breakpoint-up(xl) {
			margin-left: 2rem;
		}
	}
}
