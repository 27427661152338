.alert {
    &.critical {
        @include media-breakpoint-up(lg) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }

        .critical-link {
            flex-shrink: 0;
            width: 1.5rem;
            height: 1.5rem;
            background-image: escape-svg($carousel-control-next-icon-bg);
            margin-left: 0.5rem;

            @include media-breakpoint-up(lg) {
                margin-left: 1rem;
                width: 2rem;
                height: 2rem;
            }
        }
    }

    &.alert-lightGreen {
        @extend .alert-success;
    }

    &.alert-darkGreen {
        @include alert-variant($green-dark, $green-dark, $white);

        a {
            color: inherit;
        }

        .critical-link {
            background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>"));
        }
    }
}
