section.attachments {
	li {
		margin-bottom: 0.5rem;

		a {
			text-decoration: none;
			display: flex;
			align-items: center;

			&::before {
				content: '';
				width: 3rem;
				height: 3rem;
				margin-right: 0.75rem;
				background-size: auto 80%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				transition: $transition-base;

				@include media-breakpoint-up(xl) {
					width: 3.5rem;
					height: 3.5rem;
					margin-right: 1rem;
				}
			}
		}

		&.type-pdf a::before {
			background-image: url('/images/file-icons/pdf.svg');
		}

		&.type-docx a::before {
			background-image: url('/images/file-icons/doc.svg');
		}

		&.type-pptx a::before {
			background-image: url('/images/file-icons/ppt.svg');
		}

		&.type-jpg a::before {
			background-image: url('/images/file-icons/jpg.svg');
		}

		&.type-png a::before {
			background-image: url('/images/file-icons/png.svg');
		}

		&.type-eps a::before {
			background-image: url('/images/file-icons/eps.svg');
		}

		&.type-ai a::before {
			background-image: url('/images/file-icons/ai.svg');
		}

		&.type-zip a::before {
			background-image: url('/images/file-icons/zip.svg');
		}
	}
}
