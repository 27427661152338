section.video-text {
	.play-button {
		width: min(20vw, 6rem);
		height: min(20vw, 6rem);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: escape-svg(url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xml:space="preserve" style="fill:#{$green-light}"><path d="m15 10-9 5V5l9 5z"/></svg>')) center / 65% auto no-repeat;
		border-radius: 50%;
		border: 3px solid $green-light;

		@include media-breakpoint-up(md) {
			width: 5rem;
			height: 5rem;
		}

		@include media-breakpoint-up(lg) {
			width: 6.25rem;
			height: 6.25rem;
		}
	}

	.buttons {
		margin-top: 2rem;

		@include media-breakpoint-up(xl) {
			margin-top: 2.75rem;
		}
	}
}
