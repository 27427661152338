section.subpage-teasers {
	.row {
		row-gap: var(--section-padding);
	}
	.col-teaser {
		@include media-breakpoint-up(xxl) {
			max-width: 38.5rem;
		}

		img, p:last-of-type {
			display: block;
			margin-bottom: 2rem;

			@include media-breakpoint-up(xl) {
				margin-bottom: 2.5rem;
			}

			@include media-breakpoint-up(xxl) {
				margin-bottom: 3rem;
			}
		}
	}
}
