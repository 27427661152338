ul.pagination {
	gap: 0.75rem;

	@include media-breakpoint-up(xl) {
		gap: 1rem;
	}

	li {
		.page-link {
			height: 100%;
			display: grid;
			place-content: center;
		}

		&:not(.text) {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}

			.page-link {
				min-width: 2.75rem;
				min-height: 2.75rem;
				border-radius: 50%;

				@include media-breakpoint-up(xl) {
					min-width: 3rem;
					min-height: 3rem;
				}
			}
		}
	}
}
