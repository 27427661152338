section.hero {
	$hero-breakpoint: lg;

	position: relative;
	height: 36.25rem;

    @include media-breakpoint-up(lg) {
        height: 28rem;
    }

    @include media-breakpoint-up(xxl) {
        height: 33rem;
    }

	&::before {
		content: '';
		height: 36.25rem;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: var(--bg-mobile) var(--bg-focus-mobile, center) / cover no-repeat;

		@include media-breakpoint-up($hero-breakpoint) {
			height: 100%;
			background: var(--bg) var(--bg-focus, center) / cover no-repeat;
		}
	}

	.container {
		@media (min-width: 1600px) {
			max-width: 1560px;
		}
	}

	.container, .row {
		height: 100%;

		@include media-breakpoint-only(xs) {
			--bs-gutter-x: 0;
		}
	}

	.patch {
        $hero-patch-width: 38.5rem;
        $hero-patch-height: 35.5rem;
		width: $hero-patch-width;
		height: $hero-patch-height;
		max-width: calc(100% - 0.5rem);
		max-height: 93vw;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;

		@include media-breakpoint-up(sm) {
			margin: 0 auto;
		}

		@include media-breakpoint-up(md) {
			width: $hero-patch-width * .75;
			height: $hero-patch-height * .75;
		}

        @include media-breakpoint-up(lg) {
            width: $hero-patch-width * .72;
            height: $hero-patch-height * .72;
        }

        @include media-breakpoint-up(xl) {
            width: $hero-patch-width * .75;
            height: $hero-patch-height * .75;
        }

        @include media-breakpoint-up(xxl) {
            width: $hero-patch-width * .85;
            height: $hero-patch-height * .85;
        }

		&::before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			@extend .patch-white-bg;
			opacity: 0.8;
			transition: $transition-fade;
		}

		&:hover::before,
		&.solid::before {
			opacity: 1;
		}

		.patch-content {
			position: relative;
			z-index: 1;
			max-width: min(22.5rem, 70vw);

			@include media-breakpoint-up(lg) {
				font-size: 1.0625rem;
			}

			@include media-breakpoint-up(xl) {
				font-size: 1.125rem;
			}

			h1 {
                @include font-size(2.375rem);
                margin-bottom: 0;
			}

			h2 {
				@include font-size(2.125rem);
				margin-bottom: 0;
			}

			.btn {
				margin-top: 1rem;

                @include media-breakpoint-up(lg) {
                    margin-top: 2rem;
                }

                @include media-breakpoint-up(xxl) {
                    margin-top: 2.25rem;
                }
			}

			p {
				font-size: 0.875rem;

				@include media-breakpoint-up($hero-breakpoint) {
					font-size: inherit;
				}
			}

			p.quote {
				text-align: center;
				font-weight: 700;
				margin-top: 1.125em;
			}
		}
	}

	&.has-text {
		.patch {
			.patch-content {
				h1 {
					line-height: 1;
					margin-bottom: 0.5em;
				}
			}
		}
	}
}
