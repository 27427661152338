section.contact-blocks {
	.contact-info {
		margin-bottom: 1rem;
		color: $gray-900;
		font-weight: 400;
		letter-spacing: 1px;

		@include media-breakpoint-up(xxl) {
			margin-bottom: 1.5rem;
		}

		.contact-info-title {
			font-weight: 700;
			color: $primary;
		}

		a {
			color: inherit;

			&[href^="tel"] {
				text-decoration: none;
			}
			&[href^="tel"] {

			}
		}
	}
}
