section.story-text {
	position: relative;

    &.bg-white + section.story-text.bg-white {
        margin-top: calc(-1 * var(--section-padding) * 0.25);
    }

	h1, h2, h3, h4, h5, h6 {
        margin-top: 1.25em;
        margin-bottom: 0.5em;

        &:first-child {
            margin-top: 0;
            margin-bottom: 1.25em;
        }
	}

	.sliding-image-wrapper {
		position: relative;

		@include media-breakpoint-only(md) {
			--bs-gutter-x: 1rem;
		}

		@include media-breakpoint-down(lg) {
			max-width: 720px;
			width: 100%;
			padding-right: var(--bs-gutter-x,.75rem);
			padding-left: var(--bs-gutter-x,.75rem);
			margin-right: auto;
			margin-left: auto;
			display: flex;
			justify-content: center;
			margin-bottom: 2rem;
		}

		@include media-breakpoint-up(lg) {
			position: sticky;
			top: calc(var(--section-padding) + 8rem);
		}

		@media (min-width: 1600px) {

		}

		+ .container .col {
			@media (min-width: 1600px) {
				margin-left: 25%;
			}
		}
	}

	.sliding-image {
		@include media-breakpoint-down(lg) {
			max-width: 100%;
		}
		@include media-breakpoint-up(lg) {
			position: absolute;
			right: 2rem;
			top: calc(-1 * var(--section-padding) - 8rem);
		}

		@media (min-width: 1600px) {
			right: 4rem;
		}

		img {
			@include media-breakpoint-up(lg) {
				max-width: calc((100vw - (960px - 2rem)) / 2 + (960px - 2rem) * 5 / 12 - 2 * 2rem);
			}

			@include media-breakpoint-up(xl) {
				max-width: calc((100vw - (1140px - 2rem)) / 2 + (1140px - 2rem) * 4 / 12 - 2 * 2rem);
			}

			@include media-breakpoint-up(xxl) {
				max-width: calc((100vw - (1320px - 2rem)) / 2 + (1320px - 2rem) * 4 / 12 - 2 * 2rem);
			}

			@media (min-width: 1600px) {
				max-width: calc((100vw - (1320px - 2rem)) / 2 + (1320px - 2rem) * 3 / 12 - 2 * 4rem);
			}
		}
	}

	.intro {
		.blog-post-date {
			position: relative;
			margin-bottom: 2rem;
			display: inline-block;
			color: $green-dark;

			&::after {
				content: '';
				position: absolute;
				top: calc(100% + 0.5rem);
				left: 0;
				right: 0;
				height: 0.125rem;
				background: $green;
			}
		}

		h1 {
			margin-bottom: 0.5em;
		}

		.meta {
			font-size: 0.75rem;

			@include media-breakpoint-up(md) {
				font-size: 0.875rem;
			}

			@include media-breakpoint-up(xl) {
				font-size: 1rem;
			}
		}

		> :last-child {
			margin-bottom: 0;
			font-weight: 700;
			font-size: inherit;
		}
	}

	.col-image {
		@include media-breakpoint-up(lg) {
			padding-top: 2.25rem;
		}
	}

    .col-story-text {
        h1, h2, h3, h4, h5, h6 {
            margin-top: 1.25em;
            margin-bottom: 0.5em;
        }
    }
}
