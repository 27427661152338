section.horizontal-menu-scroll {
	padding: 1.25rem 0;

	@include media-breakpoint-up(xl) {
		padding: 1.25rem 0;
	}
	@include media-breakpoint-up(xxl) {
		padding: 1.5rem 0;
	}

	.horizontal-menu {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		overflow-x: auto;
		width: 100%;

		.horizontal-menu-item {
			color: #1f5810;
			text-align: center;
			padding: 1rem 3rem;
			white-space: nowrap;
			text-decoration: none;
			font-weight: 600;

			span {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					top: calc(100% + 0.5rem);
					left: 50%;
					right: 50%;
					height: 0.125rem;
					background: $green;
					transition: $transition_base;
				}
			}

			&:hover span {
				&::after {
					left: 0;
					right: 0;
				}
			}
		}
	}
}
