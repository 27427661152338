section.text {
	.col-text {
		> :last-child {
			margin-bottom: 0;
		}

		&:not(:last-child) {
			margin-bottom: 2rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
		}

		a:not(.btn) {
			color: inherit;
		}

		.buttons {
			margin-top: 2rem;

			@include media-breakpoint-up(xl) {
				margin-top: 2.75rem;
			}
		}

        h1, h2, h3, h4, h5, h6 {
            margin-top: 1.25em;
            margin-bottom: 0.5em;

            &:first-child {
                margin-top: 0;
                margin-bottom: 1.25em;
            }
        }
	}

	.sustainability-icons {
		display: flex;
		height: clamp(5rem, 12vw, 9.375rem);
		margin-top: 2rem;

		@include media-breakpoint-up(xl) {
			margin-top: 2.5rem;
		}
	}
}
