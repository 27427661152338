section.story-hero {
	--story-content-height: 100vh;
	min-height: max(100vh, var(--story-content-height, 0));
	position: relative;
	color: $white;
	margin-bottom: 0 !important;

	.story-header {
		background: transparent;
		position: relative;
		z-index: 1;
	}

	.story-teaser {
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		height: max(100vh, var(--story-content-height, 0));
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: var(--bg-mobile) var(--bg-focus-mobile, center) / cover no-repeat;

		@include media-breakpoint-up(lg) {
			background: var(--bg) var(--bg-focus, center) / cover no-repeat;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $gray-900;
			opacity: var(--overlay-opacity, 0.5);
		}

		.container {
			position: relative;
			z-index: 2;
			padding-top: var(--header-height);
			padding-bottom: calc(var(--header-height) * 0.25);

			@include media-breakpoint-up(lg) {
				padding-top: calc(var(--header-height) * 0.25);
			}
		}

		.intro {

			@media (min-width: 1600px) {
				margin-left: 2rem;
			}

			.blog-post-date {
				position: relative;
				margin-bottom: 2rem;
				display: inline-block;

				&::after {
					content: '';
					position: absolute;
					top: calc(100% + 0.5rem);
					left: 0;
					right: 0;
					height: 0.125rem;
					background: $white;
				}
			}

			h1 {
				color: $white;
				margin-bottom: 0.5em;
			}

			.meta {
				font-size: 0.75rem;

				@include media-breakpoint-up(md) {
					font-size: 0.875rem;
				}

				@include media-breakpoint-up(xl) {
					font-size: 1rem;
				}
			}

			> :last-child {
				margin-bottom: 0;
				font-weight: 700;
				font-size: inherit;
			}
		}

		&.clone {
			color: transparent;
			position: absolute;
			background: none !important;

			&::before {
				background: transparent;
			}

			h1 {
				color: inherit;
			}

			.intro {
				.blog-post-date::after {
					background: transparent;
				}
			}
		}
	}

	+ section {
		&::before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			height: 100vh;
			pointer-events: none;
		}
	}

	~ section {
		position: relative;
		z-index: 1;
	}

	&.non-sticky {
		.story-teaser {
			position: absolute;
		}

		+ section {
			&::before {
				display: none;
			}
		}
	}
}
