section.icons {
	--col-max-width: 18.5rem;

	.row {
		gap: 1.5rem 0;

		@include media-breakpoint-up(md) {
			row-gap: 2rem;
		}

        @include media-breakpoint-up(xl) {
            column-gap: 2.25rem;
        }

        @include media-breakpoint-up(xxl) {
			column-gap: 2.5rem;
		}
	}

	.col {
		max-width: var(--col-max-width);

		> :last-child {
			margin-bottom: 0;
		}
	}

	.ratio {
        max-width: min(10rem, 100%);
        margin-left: auto;
        margin-right: auto;

		.img-wrapper {
			display: flex;
			justify-content: center;
			align-items: flex-end;

			img {

			}
		}

        &.ratio-1x1 {
            max-width: min(12rem, 100%);
        }
	}

	h3 {
		margin: 1rem 0 0.75rem;
	}

	p {
		font-weight: 700;
		color: $green-dark;
		letter-spacing: 0;
	}

	.only-headings {
		row-gap: 2.75rem;

		@include media-breakpoint-up(xl) {
			row-gap: 3.25rem;
		}

		h3 {
			margin-top: 1.5rem;

			@include media-breakpoint-up(xl) {
				margin-top: 1.75rem;
			}
		}
	}
}
