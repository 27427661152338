.tip {
	.col-tip {
		max-width: min(calc(100vw - #{$grid-gutter-width} * 0.5), 35.125rem);
		max-height: calc((100vw - #{$grid-gutter-width} * 0.5) * 0.93);

		.bulb {
			display: inline-block;
			width: 9.875rem;
			height: 6.375rem;
			max-width: 44%;
		}

		h3 {
			margin: 0 0 0.5em 0;

			@include media-breakpoint-up(sm) {
				margin: 1em 0;
			}
		}

		p {
			font-weight: 700;
			color: $green-dark;

			@include media-breakpoint-down(sm) {
				font-size: 0.875rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 1rem;
			}
		}

		&.bg-white {
			.bulb {
				background: escape-svg(url('data:image/svg+xml,<svg height="101.121" width="156.564" xmlns="http://www.w3.org/2000/svg"><path d="M51.741 3.216 57.312 0l9.185 15.908-5.571 3.216zM34.584 21.954l3.216-5.571 15.91 9.185-3.217 5.571zM29.094 46.761v-6.433h18.37v6.433zM127.165 42.442v6.433h-18.37v-6.433zM119.515 18.214l3.216 5.571-15.908 9.185-3.216-5.571zM100.777 1.056l5.571 3.216-9.185 15.91-5.571-3.217z" fill="#d5e0c8"/><g fill="none" stroke="#004712" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.32"><path d="M88.873 62.225a21.989 21.989 0 1 0-21.485 0"/><path d="m87.457 40.804-9.468 10.423-9.468-10.423M67.387 62.225v20.641a4.609 4.609 0 0 0 4.609 4.609h12.268a4.609 4.609 0 0 0 4.609-4.609V62.225M67.529 70.463h20.92M67.529 78.9h13.004M78.13 50.312v12.801M1.66 99.46h153.244"/></g></svg>')) center / contain no-repeat;
			}
		}

		&.text-white {
			position: relative;
			width: 35.125rem;
			height: 32.5rem;
			display: flex;
			justify-content: center;
			align-items: center;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				@extend .patch-green-bg;

				@include media-breakpoint-up(sm) {
					left: $grid-gutter-width * 0.5;
					right: $grid-gutter-width * 0.5;
				}
			}

			.tip-wrapper {
				max-width: 75%;
				position: relative;
				z-index: 1;
				left: 1rem;
				top: -1rem;
			}

			.bulb {
				background: escape-svg(url('data:image/svg+xml,<svg height="101.121" width="156.564" xmlns="http://www.w3.org/2000/svg"><path d="M51.741 3.216 57.312 0l9.185 15.908-5.571 3.216zM34.584 21.954l3.216-5.571 15.91 9.185-3.217 5.571zM29.094 46.761v-6.433h18.37v6.433zM127.165 42.442v6.433h-18.37v-6.433zM119.515 18.214l3.216 5.571-15.908 9.185-3.216-5.571zM100.777 1.056l5.571 3.216-9.185 15.91-5.571-3.217z" fill="#fff"/><g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.32"><path d="M88.873 62.225a21.989 21.989 0 1 0-21.485 0"/><path d="m87.457 40.804-9.468 10.423-9.468-10.423M67.387 62.225v20.641a4.609 4.609 0 0 0 4.609 4.609h12.268a4.609 4.609 0 0 0 4.609-4.609V62.225M67.529 70.463h20.92M67.529 78.9h13.004M78.13 50.312v12.801M1.66 99.46h153.244"/></g></svg>')) center / contain no-repeat;
			}

			h3, p {
				color: inherit;
			}

            p {
                max-width: 22.5rem;
            }

			.btn-success {
				@include button-variant(
						$white, $white, $green,
						tint-color($green, 90%), tint-color($green, 90%), $green-btn,
						tint-color($green, 90%), tint-color($green, 90%), $green-btn
				);
			}
		}

		&.text-center {
			.tip-wrapper {
				left: 0;
				top: 0;
			}
		}
	}
}
