section.brands {
	a {
		text-decoration: none;
		color: inherit;
	}

	p {
		max-width: 25rem;
	}

    @include media-breakpoint-up(lg) {
        .row .row {
            height: 100%;
            flex-direction: column;

            @include media-breakpoint-only(md) {
                flex-direction: row;
            }
        }
        .col-image {
            flex-grow: 0;
        }
        .col-text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            > * {
                flex-grow: 0;
            }

            .brand-description {
                flex-grow: 1;
            }

            .brand-image {
                margin-bottom: 0;
            }
        }
    }
}
