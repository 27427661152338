section.quote {
	blockquote {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		h2 {
			margin-bottom: 2rem;
			width: 100%;
			@include font-size(2.5rem);

			@include media-breakpoint-up(xl) {
				margin-bottom: 2.5rem;
			}
		}
	}
}
