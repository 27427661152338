section.blog-narrow {

	.intro {
		.lead {
			font-weight: 700;
			margin: 2rem 0;
			font-size: inherit;

			@include media-breakpoint-up(md) {
				margin: 3rem 0;
			}

			@include media-breakpoint-up(xl) {
				margin: 3.75rem 0;
			}
		}
	}

	.container.narrow {

		@include media-breakpoint-up(lg) {
			max-width: 60rem;
		}

		h2, h3, h4, h5, h6 {
			&:not(:first-child) {
				margin: 1.75em 0 1em;
			}
		}

		a {
			color: inherit;
		}
	}

	.blog-post-image {
		max-width: 62.5rem;
		display: block;
		margin: 0 auto 3rem;
	}

	.blog-post-date {
		color: $green-dark;
		position: relative;
		margin-bottom: 2rem;
		display: inline-block;

		&::after {
			content: '';
			position: absolute;
			top: calc(100% + 0.5rem);
			left: 0;
			right: 0;
			height: 0.125rem;
			background: $green;
		}
	}
}
